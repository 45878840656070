<template>
    <div class="modal" :class="{ 'is-active': modalOpen }">
        <ValidationObserver v-slot="{ handleSubmit }">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p v-if="action == 'edit'" class="modal-card-title">Editar Local</p>
                    <p v-else class="modal-card-title">Criar Local</p>
                    <button @click="closeModal()" class="delete" aria-label="close"></button>
                </header>
                <section class="modal-card-body" style="background: white;">
                    <b-field label="Nome">
                        <div class="control">
                            <ValidationProvider name="Nome" rules="required" v-slot="{ errors }">
                                <input v-model="place.name" class="input" type="text" />
                                <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                            </ValidationProvider>
                        </div>
                    </b-field>

                    <b-field label="País">
                        <ValidationProvider name="País" rules="required" v-slot="{ errors }">
                            <b-select :disabled="countryDisabled" @blur="getCoordinates" @input="getDistricts()"
                                v-model="countrySelected" name="origin" expanded>
                                <option v-for="option in countries" v-bind:value="option">
                                    {{ option.country }}
                                </option>
                            </b-select>
                            <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                        </ValidationProvider>
                    </b-field>

                    <b-field label="Distrito">
                        <ValidationProvider name="Distrito" rules="required" v-slot="{ errors }">
                            <b-select :disabled="districtDisabled" @blur="getCoordinates" @input="getMunicipalities()"
                                v-model="districtSelected" name="origin" expanded>
                                <option v-for="option in districts" v-bind:value="option">
                                    {{ option.district_name }}
                                </option>
                            </b-select>
                            <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                        </ValidationProvider>
                    </b-field>

                    <b-field label="Município">
                        <ValidationProvider name="Município" rules="required" v-slot="{ errors }">
                            <b-select @input="enableAddress" @blur="getCoordinates" :disabled="municipalityDisabled"
                                v-model="municipalitySelected" name="origin" expanded>
                                <option v-for="option in municipalities" v-bind:value="option">
                                    {{ option.municipality_name }}
                                </option>
                            </b-select>
                            <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                        </ValidationProvider>
                    </b-field>
                    <b-field label="Morada">
                        <div class="control">
                            <ValidationProvider name="Morada" rules="required" v-slot="{ errors }">
                                <input :disabled="addressDisabled" @blur="getCoordinates" v-model="place.address"
                                    class="input" type="text" placeholder required />
                                <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                            </ValidationProvider>
                        </div>
                    </b-field>

                    <b-field label="Código postal">
                        <div class="control">
                            <ValidationProvider name="Código postal" :rules="{regex:/^\d{4}\-\d{3}?$/, required:true}"
                                v-slot="{ errors }">
                                <input @input="addDash" v-model="place.zip_code" maxlength="8" class="input" type="text"
                                    placeholder="XXXX-XXX" />
                                <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                            </ValidationProvider>
                        </div>
                    </b-field>

                    <b-field label="Coordenadas">

                        <div class="control">
                            <!-- <ValidationProvider name="Coordenadas (latitude, longitude)" rules="required" v-slot="{ errors }"> -->
                            <input placeholder="Latitude" :disabled="!mapEnabled" v-model="place.coordinates.lat"
                                class="input is-6" type="number" required>
                            <input placeholder="Longitude" :disabled="!mapEnabled" v-model="place.coordinates.lng"
                                class="input is-6" type="number" required />
                            <!-- <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                                </ValidationProvider> -->
                            <br><br>
                            <MapView @changemarker="changeCoordinates" v-if="mapEnabled" :zoom="13"
                                :center="place.coordinates" :markers="[{position: place.coordinates, draggable: true}]" />
                        </div>
                        <b-loading :is-full-page="false" :active.sync="isLoadingMap"></b-loading>
                    </b-field>



                </section>
                <footer class="modal-card-foot">
                    <b-button :loading="isLoading" v-if="action == 'edit'" class="is-primary-color is-fullwidth is-medium"
                        @click="handleSubmit(_editPlace)">Guardar</b-button>
                    <b-button :loading="isLoading" v-else class="is-primary-color is-fullwidth is-medium"
                        @click="handleSubmit(createPlace)">Guardar
                    </b-button>
                </footer>
            </div>
        </ValidationObserver>
    </div>

</template>

<script>
    import {
        createPlace,
        updatePlace,
        getCoordinates
    } from "./../../api/places/places";

    import Countries from "@/config/Countries.json"
    import Districts from "@/config/Districts.json"
    import Municipalities from "@/config/Municipality.json"
    import MapView from '../Map/MapView';

    export default {
        name: "ModalPlaces",
        components: {
            MapView
        },
        props: {
            action: {
                type: String,
                default: "add"
            },
            place_edit: Object,
            modalOpen: Boolean
        },
        created() {
            if (this.action != "edit") return;

            this.place = this.place_edit;

            if (this.place.country) {
                for (let c of this.countries)
                    if (c.country == this.place.country)
                        this.countrySelected = c;
                this.getDistricts();
                if (this.place.district) {
                    for (let c of this.districts)
                        if (c.district_name == this.place.district)
                            this.districtSelected = c;
                    this.getMunicipalities();
                    if (this.place.municipality) {
                        for (let c of this.municipalities)
                            if (c.municipality_name == this.place.municipality)
                            {
                                this.municipalitySelected = c;
                                this.addressDisabled = false;
                                this.mapEnabled = true;
                            }
                                
                    }
                }

            }
        },
        data() {
            return {
                districts: [],
                municipalities: [],
                municipalityDisabled: true,
                municipalitySelected: null,
                districtDisabled: true,
                districtSelected: null,
                countries: Countries,
                countrySelected: null,
                countryDisabled: false,
                addressDisabled: true,
                mapEnabled: false,
                isLoading: false,
                coordinates: null,
                isLoadingMap: false,
                place: {
                    address: null,
                    municipality: null,
                    district: null,
                    country: null,
                    name: null,
                    zip_code: null,
                    coordinates: {
                        lat: 0,
                        lng: 0
                    }
                }
            }
        },
        methods: {
            closeModal() {
                this.$emit("closemodal");
            },
            addDash() {
                if (this.place.zip_code.length == 4) this.place.zip_code += "-";
            },
            _editPlace() {
                this.isLoading = true;
                this.place.country = this.countrySelected.country;
                this.place.district = this.districtSelected.district_name;
                this.place.municipality = this.municipalitySelected.municipality_name;

                updatePlace(this.place._id, this.place)
                    .then(response => {
                        this.$emit("editplace", response.data.body);

                        this.$buefy.toast.open({
                            message: "Local editado com sucesso.",
                            type: "is-success",
                            position: "is-bottom"
                        });
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error)
                        this.$buefy.toast.open({
                            message: "Ocorreu um erro!",
                            type: "is-danger",
                            position: "is-bottom"
                        });
                        this.isLoading = false;
                    });

            },
            createPlace() {
                this.isLoading = true;
                this.place.country = this.countrySelected.country;
                this.place.district = this.districtSelected.district_name;
                this.place.municipality = this.municipalitySelected.municipality_name;

                createPlace(this.place)
                    .then(response => {
                        this.$emit("addplace", response.data.body);

                        this.$buefy.toast.open({
                            message: "Local adicionado com sucesso.",
                            type: "is-success",
                            position: "is-bottom"
                        });
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error)
                        this.$buefy.toast.open({
                            message: "Ocorreu um erro!",
                            type: "is-danger",
                            position: "is-bottom"
                        });
                        this.isLoading = false;
                    });
            },
            getDistricts() {
                this.districts = [];
                this.districtSelected = null;
                for (let m of Districts) {
                    if (this.countrySelected.country_id == m.country_id)
                        this.districts.push(m);
                }
                this.districtDisabled = false;
            },
            getMunicipalities() {
                this.municipalities = [];
                this.municipalitySelected = null;
                for (let m of Municipalities) {
                    if (this.districtSelected.district_code == m.district_code)
                        this.municipalities.push(m);
                }
                this.municipalityDisabled = false;
            },
            enableAddress() {
                this.addressDisabled = false;
            },
            getCoordinates() {
                if (this.place.address && this.municipalitySelected.municipality_name && this.districtSelected
                    .district_name && this.countrySelected.country) {
                    this.isLoadingMap = true;
                    let place = this.place.address + " " + this.municipalitySelected.municipality_name + " " + this
                        .districtSelected.district_name + " " + this.countrySelected.country;
                    getCoordinates(place)
                        .then(response => {
                            this.place.coordinates = response.data.candidates[0].geometry.location;
                            this.mapEnabled = true;
                            this.isLoadingMap = false;
                        }).catch(error => {
                            console.log(error)
                            this.mapEnabled = true;
                            this.isLoadingMap = false;
                        });
                }

            },
            changeCoordinates(e) {
                this.place.coordinates = e;
            }
        }
    };
</script>

<style scoped>
    .has-addons {
        display: unset !important;
    }

    .zc_1 {
        width: 70px !important;
    }

    .zc_2 {
        width: 55px !important;
    }

    .is-6 {
        width: 50% !important;
    }
</style>