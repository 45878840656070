<template>
	<div>
		<div class="columns" style="min-height: 100vh;">
			<div class="column is-narrow" style="width: 200px;"></div>
			<div class="column is-10-touch is-offset-1-mobile">
				<div>
					<div class="tabs is-centered">
						<ul>
							<li
								@click="$router.push({name: 'Backoffice-Institutions'})"
								:class="$route.name=='Backoffice-Institutions' ? 'is-active institution' : ' ' "
							>
								<a>Instituições</a>
								<!-- <hr> -->
							</li>
							<li
								@click="$router.push({name: 'Backoffice-Glad'})"
								:class="$route.name=='Backoffice-Glad' ? 'is-active glads' : ''"
							>
								<a>Glad</a>
								<!-- <hr> -->
							</li>
							<li
								@click="$router.push({name: 'Backoffice-Places'})"
								:class="$route.name=='Backoffice-Places' ? 'is-active places' : ''"
							>
								<a>Locais</a>
								<!-- <hr> -->
							</li>
							<li
								@click="$router.push({name: 'Backoffice-Partners'})"
								:class="$route.name=='Backoffice-Partners' ? 'is-active partner' : ' ' "
							>
								<a>Munícipios Parceiros</a>
								<!-- <hr> -->
							</li>
							<li
								@click="$router.push({name: 'Backoffice-Info'})"
								:class="$route.name=='Backoffice-Info' ? 'is-active info' : ' ' "
							>
								<a>Info</a>
								<!-- <hr> -->
							</li>
						</ul>
					</div>
					<!-- <FilterNav/> -->
				</div>
				<div>
					<section class="hero">
						<!-- <div class="container"> -->
						<br />
						<Institution v-show="$route.name=='Backoffice-Institutions'" />

						<Glads v-show="$route.name=='Backoffice-Glad'" />

						<Places v-show="$route.name=='Backoffice-Places'" />

						<Partners v-show="$route.name=='Backoffice-Partners'" />

						<Info v-show="$route.name=='Backoffice-Info'" />
					</section>
				</div>
			</div>
			<div class="column is-narrow" style="width: 200px;"></div>
		</div>
	</div>
</template>

<script>
	//COMPONENTS
	import Institution from "@/components/Backoffice/Institution.vue";
	import Glads from "@/components/Backoffice/Glads.vue";
	import Places from "../components/Backoffice/Places";
	import Partners from "../components/Backoffice/Partners";
	import Info from "../components/Backoffice/Info";

	import { get_glads } from "@/api/user/user";

	import { getPlaces } from "@/api/places/places.js";

	import { getPartners } from "@/api/partners/partners";

	import { getInstitutions } from "@/api/institution/institution";

	import { backoffice } from "../mixins/modules/backoffice";

	export default {
		name: "backoffice",
		mixins: [backoffice],
		components: {
			Institution,
			Glads,
			Places,
			Partners,
			Info,
		},
		created() {
			this.getAPIGlads();
			this.getAPIInstitutions();
			this.getAPIPlaces();
			this.getAPIPartners();
		},
		data() {
			return {
				user: {},
				modalOpen: false,
				showByIndex: null,
				selectedOrigin: null,
				defaultOpenLevel: 0,
			};
		},
		methods: {
			getAPIGlads() {
				this.isLoading = true;
				get_glads()
					.then((response) => {
						this.setGlads(response.data.body);
					})
					.catch((error) => {
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
			modal() {
				this.modalOpen = !this.modalOpen;
			},
			getAPIInstitutions() {
				getInstitutions()
					.then((response) => {
						this.setInstitutions(response.data.body);
					})
					.catch((error) => {
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
			getAPIPlaces() {
				getPlaces()
					.then((response) => {
						this.setPlaces(response.data.body);
					})
					.catch((error) => {
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
			getAPIPartners() {
				getPartners()
					.then((response) => {
						this.setPartners(response.data.body);
					})
					.catch((error) => {
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "../assets/sass/main";

	.tabs ul {
		border: none;
	}

	.tabs li.is-active a {
		border-bottom: solid 5px $primary-color;
		color: $dark;
	}
</style>