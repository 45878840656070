<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>
		<div class="columns is-multiline is-mobile is-centered">
			<div
				style="text-align: center;
    margin-bottom: 1rem;"
				class="column is-fullwidth is-centered"
			>
				<h5 class="title is-5">Instituições</h5>
			</div>
		</div>

		<div class="columns is-multiline is-mobile is-centered">
			<template>
				<b-table
					:data="getInstitutions"
					:paginated="true"
					:per-page="10"
					:current-page.sync="currentPage"
					@page-change="changePage"
					:mobile-cards="true"
				>
					<b-table-column field="name" sortable label="Nome" centered v-slot="props">{{ props.row.name }}</b-table-column>
					<b-table-column
						field="entity"
						sortable
						label="Entidade"
						centered
						v-slot="props"
					>{{ getEntity(props.row.entity) }}</b-table-column>
					<b-table-column
						field="address"
						label="Morada"
						centered
						v-slot="props"
					>{{ props.row.location.address }}</b-table-column>

					<b-table-column
						field="location.municipality"
						sortable
						label="Município"
						centered
						v-slot="props"
					>{{ props.row.location.municipality }}</b-table-column>

					<b-table-column
						field="location.district"
						sortable
						label="Distrito"
						centered
						v-slot="props"
					>{{ props.row.location.district }}</b-table-column>

					<b-table-column
						field="location.country"
						sortable
						label="País"
						centered
						v-slot="props"
					>{{ props.row.location.country }}</b-table-column>
					<b-table-column field="actions" label="Ações" centered v-slot="props">
						<b-button
							style="margin-right: 5px;"
							type="is-secondary-color is-small"
							@click="openEditInstitution(JSON.parse(JSON.stringify(props.row)))"
						>
							<b-icon icon="border-color" size="is-small"></b-icon>
						</b-button>
						<b-button
							style="margin-right: 5px;"
							type="is-danger is-small"
							@click="deleteInstitution(JSON.parse(JSON.stringify(props.row)))"
						>
							<b-icon icon="delete" size="is-small"></b-icon>
						</b-button>
						<b-button type="is-small" @click="shareInstitution(JSON.parse(JSON.stringify(props.row)))">
							<b-icon icon="share-variant" size="is-small"></b-icon>
						</b-button>
					</b-table-column>

					<template slot="empty">
						<section class="section">
							<div class="content has-text-grey has-text-centered">
								<p>Não existem instituições!</p>
							</div>
						</section>
					</template>
				</b-table>
			</template>
		</div>

		<div style="margin-top:1rem;" class="columns is-multiline is-mobile is-centered">
			<div class="column is-7"></div>
			<div class="column">
				<b-button @click="openModal('add')" class="is-primary-color">Criar instituição</b-button>
			</div>
		</div>

		<ModalInstitution
			v-if="modalOpen"
			@editinstitution="editInstitution"
			@addinstitution="addInstitution"
			@closemodal="modal"
			:institution_edit="institution_edit"
			:modalOpen="modalOpen"
			:action="action"
		/>

		<ModalShareInstitution
			v-if="modalShareOpen"
			@closemodal="closeModalShare"
			:institution="institution_share"
		/>
	</div>
</template>


<script>
	//API
	import { removeInstitution } from "./../../api/institution/institution";

	import Entities from "../../config/EntityTypes";

	import { backoffice } from "../../mixins/modules/backoffice";

	import ModalInstitution from "./ModalInstitution";
	import ModalShareInstitution from "./ModalShareInstitution";

	export default {
		name: "Institution",
		components: {
			ModalInstitution,
			ModalShareInstitution,
		},
		props: {},
		mixins: [backoffice],
		created() {
			this.currentPage = this.$route.query.page
				? parseInt(this.$route.query.page)
				: 1;
		},
		data() {
			return {
				isEmpty: false,
				currentPage: 1,
				isLoading: false,
				modalOpen: false,
				modalShareOpen: false,
				action: null,
				institution_edit: null,
				institution_share: null,
			};
		},
		methods: {
			shareInstitution(ins) {
				this.institution_share = ins;
				this.modalShareOpen = true;
			},
			closeModalShare() {
				this.modalShareOpen = false;
			},
			getEntity(ent) {
				let name = Entities.filter(
					(e) => e.id.toLowerCase() == ent.toLowerCase()
				);
				if (name.length <= 0) return ent;
				return name[0].name;
			},
			deleteInstitution(institution) {
				this.$buefy.dialog.confirm({
					title: "Remover instituição",
					message:
						"Tem a certeza que pretende remover a instituição <b>" +
						institution.name +
						"</b>? Esta ação não pode ser desfeita!",
					confirmText: "Sim",
					cancelText: "Não",
					type: "is-danger",
					hasIcon: true,
					onConfirm: () => {
						this.isLoading = true;

						removeInstitution(institution._id)
							.then((response) => {
								this.$buefy.toast.open({
									message: "Instituição removida com sucesso.",
									type: "is-success",
									position: "is-bottom",
								});

								let u = this.getUserInfo;
								u.institutions.map((v, i) => {
									if (v._id == institution._id)
										u.institutions.splice(i);
								});
								this.addUser(u);

								this.removeInstitution(institution._id);

								this.isLoading = false;
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: "Ocorreu um erro!",
									type: "is-danger",
									position: "is-bottom",
								});
								this.isLoading = false;
							});
					},
				});
			},
			editInstitution(ins) {
				this.modal();
				this.updateInstitution(ins);
			},
			openEditInstitution(ins) {
				this.institution_edit = ins;
				this.openModal("edit");
			},
			addInstitution(new_institution) {
				this.modal();
				this.setInstitutions(
					this.getInstitutions.concat([new_institution])
				);
			},
			openModal(action) {
				this.action = action;
				this.modalOpen = true;
			},
			modal() {
				this.modalOpen = !this.modalOpen;
			},
			changePage(a) {
				this.scrollToTop();
				if (this.$route.query != undefined) {
					let q = JSON.parse(JSON.stringify(this.$route.query));
					q.page = a;
					this.$router.push({ query: q });
				} else {
					this.$router.push({ query: { page: a } });
				}
			},
		},
	};
</script>