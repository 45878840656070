<template>
    <div class="modal" :class="{ 'is-active': modalOpen }">
        <ValidationObserver v-slot="{ handleSubmit }">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p v-if="action == 'edit'" class="modal-card-title">Editar Instituição</p>
                    <p v-else class="modal-card-title">Criar Instituição</p>
                    <button @click="closeModal()" class="delete" aria-label="close"></button>
                </header>
                <section class="modal-card-body" style="background: white;">
                    <b-field label="Nome">
                        <div class="control">
                            <ValidationProvider name="Nome" rules="required" v-slot="{ errors }">
                                <input v-model="institution.name" class="input" type="text" />
                                <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                            </ValidationProvider>
                        </div>
                    </b-field>
                    <b-field label="Entidade">
                        <ValidationProvider name="Entidade" rules="required" v-slot="{ errors }">
                            <b-select v-model="institution.entity" name="origin" expanded
                                placeholder="Selecione o tipo de entidade">
                                <option v-for="type in entityTypes" v-bind:value="type.id" v-bind:key="type.id"
                                    selected>
                                    {{ type.name }}
                                </option>
                            </b-select>
                            <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                        </ValidationProvider>
                    </b-field>

                    <b-field label="País">
                        <ValidationProvider name="País" rules="required" v-slot="{ errors }">
                            <b-select :disabled="countryDisabled" @input="getDistricts()" v-model="countrySelected"
                                name="origin" expanded>
                                <option :key="i" v-for="(option, i) in countries" v-bind:value="option">
                                    {{ option.country }}
                                </option>
                            </b-select>
                            <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                        </ValidationProvider>
                    </b-field>

                    <b-field label="Distrito">
                        <ValidationProvider name="Distrito" rules="required" v-slot="{ errors }">
                            <b-select :disabled="districtDisabled" @input="getMunicipalities()"
                                v-model="districtSelected" name="origin" expanded>
                                <option v-for="(option, i) in districts" :key="i" v-bind:value="option">
                                    {{ option.district_name }}
                                </option>
                            </b-select>
                            <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                        </ValidationProvider>
                    </b-field>

                    <b-field label="Município">
                        <ValidationProvider name="Município" rules="required" v-slot="{ errors }">
                            <b-select :disabled="municipalityDisabled" v-model="municipalitySelected" name="origin"
                                expanded>
                                <option v-for="(option, i) in municipalities" :key="i" v-bind:value="option">
                                    {{ option.municipality_name }}
                                </option>
                            </b-select>
                            <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                        </ValidationProvider>
                    </b-field>
                    <b-field label="Morada">
                        <div class="control">
                            <ValidationProvider name="Morada" rules="required" v-slot="{ errors }">
                                <input v-model="institution.location.address" class="input" type="text" placeholder
                                    required />
                                <span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
                            </ValidationProvider>
                        </div>
                    </b-field>

                </section>
                <footer class="modal-card-foot">
                    <b-button :loading="isLoading" v-if="action == 'edit'" class="is-primary-color is-fullwidth is-medium"
                        @click="handleSubmit(_editInstitution)">Guardar</b-button>
                    <b-button :loading="isLoading" v-else class="is-primary-color is-fullwidth is-medium"
                        @click="handleSubmit(createInstitution)">Guardar
                    </b-button>
                </footer>
            </div>
        </ValidationObserver>
    </div>

</template>

<script>
    import {
        createInstitution,
        updateInstitution
    } from "./../../api/institution/institution";

    import Countries from "@/config/Countries.json"
    import Districts from "@/config/Districts.json"
    import Municipalities from "@/config/Municipality.json"
    import EntityTypes from "@/config/EntityTypes.json"


    export default {
        name: "ModalInstitution",
        components: {},
        props: {
            action: {
                type: String,
                default: "add"
            },
            institution_edit: Object,
            modalOpen: Boolean
        },
        mixins: [],
        created() {
            if (this.action != "edit") return;

            this.institution = this.institution_edit;

            if (this.institution.location.country) {
                for (let c of this.countries)
                    if (c.country == this.institution.location.country)
                        this.countrySelected = c;
                this.getDistricts();
                if (this.institution.location.district) {
                    for (let c of this.districts)
                        if (c.district_name == this.institution.location.district)
                            this.districtSelected = c;
                    this.getMunicipalities();
                    if (this.institution.location.municipality) {
                        for (let c of this.municipalities)
                            if (c.municipality_name == this.institution.location.municipality)
                                this.municipalitySelected = c;
                    }
                }

            }
        },
        data() {
            return {
                districts: [],
                municipalities: [],
                municipalityDisabled: true,
                municipalitySelected: null,
                entityTypes: EntityTypes,
                districtDisabled: true,
                districtSelected: null,
                countries: Countries,
                countrySelected: null,
                countryDisabled: false,
                isLoading: false,
                institution: {
                    location: {
                        address: "",
                        municipality: "",
                        district: "",
                        country: ""
                    },
                    name: "",
                    entity: "",
                    NIF: null,
                    email: "",
                    contact: ""
                }
            }
        },
        methods: {
            closeModal() {
                this.$emit("closemodal");
            },
            _editInstitution() {
                this.isLoading = true;
                this.institution.location.country = this.countrySelected.country;
                this.institution.location.district = this.districtSelected.district_name;
                this.institution.location.municipality = this.municipalitySelected.municipality_name;

                updateInstitution(this.institution._id, this.institution)
                    .then(response => {
                        this.$emit("editinstitution", response.data.body);

                        let u = this.getUserInfo;
                        u.institutions.map((v, i) => {
                            if (v._id == this.institution._id)
                                u.institutions[i] = response.data.body
                        })
                        this.addUser(u);

                        this.$buefy.toast.open({
                            message: "Instituição editada com sucesso.",
                            type: "is-success",
                            position: "is-bottom"
                        });
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error)
                        this.$buefy.toast.open({
                            message: "Ocorreu um erro!",
                            type: "is-danger",
                            position: "is-bottom"
                        });
                        this.isLoading = false;
                    });

            },
            createInstitution() {
                this.isLoading = true;
                this.institution.location.country = this.countrySelected.country;
                this.institution.location.district = this.districtSelected.district_name;
                this.institution.location.municipality = this.municipalitySelected.municipality_name;

                let data = {
                    location: {
                        address: this.institution.location.address,
                        municipality: this.institution.location.municipality,
                        district: this.institution.location.district,
                        country: this.institution.location.country
                    },
                    name: this.institution.name,
                    entity: this.institution.entity,
                    //NIF: this.institution.NIF,
                    //email: this.institution.email,
                    //contact: this.institution.contact
                }
                createInstitution(data)
                    .then(response => {
                        this.$emit("addinstitution", response.data.body);

                        let u = this.getUserInfo;
                        u.institutions.push(response.data.body);
                        this.addUser(u);

                        this.$buefy.toast.open({
                            message: "Instituição adicionada com sucesso.",
                            type: "is-success",
                            position: "is-bottom"
                        });
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error)
                        this.$buefy.toast.open({
                            message: "Ocorreu um erro!",
                            type: "is-danger",
                            position: "is-bottom"
                        });
                        this.isLoading = false;
                    });
            },
            getDistricts() {
                this.districts = [];
                this.districtSelected = null;
                for (let m of Districts) {
                    if (this.countrySelected.country_id == m.country_id)
                        this.districts.push(m);
                }
                this.districtDisabled = false;
            },
            getMunicipalities() {
                this.municipalities = [];
                this.municipalitySelected = null;
                for (let m of Municipalities) {
                    if (this.districtSelected.district_code == m.district_code)
                        this.municipalities.push(m);
                }
                this.municipalityDisabled = false;
            },
        }
    };
</script>