<template>

    <div>
        <div class="columns is-multiline is-mobile is-centered">
            <div style="text-align: center;
          margin-bottom: 1rem;" class="column is-fullwidth is-centered">
                <h5 class="title is-5">Info</h5>
            </div>
        </div>
        <div class="columns is-multiline is-mobile is-centered">
            <b>Downloads da PWA:</b><span>{{info.downloads}}</span>
        </div>


    </div>

</template>

<script>
    import {
        getInfo
    } from '../../api/info/info.js'

    export default {
        name: "Info",
        data() {
            return {
                info: {}
            }
        },
        created() {
            getInfo()
                .then(response => {
                    this.info = response.data;
                })
                .catch(error => {
                    console.log(error.response)
                    this.$buefy.toast.open({
                        message: "Ocorreu um erro!",
                        type: "is-danger",
                        position: "is-bottom"
                    });
                })
        }
    }
</script>

<style lang="scss" scoped>
    span {
        margin-left: 10px;
    }
</style>