<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>
		<div class="columns is-multiline is-mobile is-centered">
			<div style="text-align: center; margin-bottom: 1rem;" class="column is-fullwidth is-centered">
				<h5 class="title is-5">Munícipios Parceiros</h5>
			</div>
		</div>

		<div class="columns is-multiline is-mobile is-centered">
			<template>
				<b-table
					:data="getPartners"
					:paginated="true"
					:per-page="10"
					:current-page.sync="currentPage"
					@page-change="changePage"
					:mobile-cards="true"
				>
					<b-table-column field="name" sortable label="Nome" centered v-slot="props">{{ props.row.name }}</b-table-column>
					<b-table-column
						field="entity"
						sortable
						label="Entidade"
						centered
						v-slot="props"
					>{{ getEntity(props.row.entity) }}</b-table-column>
					<b-table-column
						field="address"
						label="Morada"
						centered
						v-slot="props"
					>{{ props.row.location.address }}</b-table-column>

					<b-table-column
						field="location.municipality"
						sortable
						label="Município"
						centered
						v-slot="props"
					>{{ props.row.location.municipality }}</b-table-column>

					<b-table-column
						field="location.district"
						sortable
						label="Distrito"
						centered
						v-slot="props"
					>{{ props.row.location.district }}</b-table-column>

					<b-table-column
						field="location.country"
						sortable
						label="País"
						centered
						v-slot="props"
					>{{ props.row.location.country }}</b-table-column>
					<b-table-column field="actions" label="Ações" centered v-slot="props">
						<b-button
							style="margin-right: 5px;"
							type="is-secondary-color is-small"
							@click="openEditPartner(JSON.parse(JSON.stringify(props.row)))"
						>
							<b-icon icon="border-color" size="is-small"></b-icon>
						</b-button>
						<b-button
							style="margin-right: 5px;"
							type="is-danger is-small"
							@click="deletePartner(JSON.parse(JSON.stringify(props.row)))"
						>
							<b-icon icon="delete" size="is-small"></b-icon>
						</b-button>
						<b-button type="is-small" @click="sharePartner(JSON.parse(JSON.stringify(props.row)))">
							<b-icon icon="share-variant" size="is-small"></b-icon>
						</b-button>
					</b-table-column>

					<template slot="empty">
						<section class="section">
							<div class="content has-text-grey has-text-centered">
								<p>Não existem munícipios parceiros!</p>
							</div>
						</section>
					</template>
				</b-table>
			</template>
		</div>

		<div style="margin-top:1rem;" class="columns is-multiline is-mobile is-centered">
			<div class="column is-7"></div>
			<div class="column">
				<b-button @click="openModal('add')" class="is-primary-color">Adicionar munícipio parceiro</b-button>
			</div>
		</div>

		<ModalPartners
			v-if="modalOpen"
			@editpartner="editPartner"
			@addpartner="addPartner"
			@closemodal="modal"
			:partner_edit="partner_edit"
			:modalOpen="modalOpen"
			:action="action"
		/>

		<ModalSharePartner v-if="modalShareOpen" @closemodal="closeModalShare" :partner="partner_share" />
	</div>
</template>


<script>
	//API
	import { removePartner } from "./../../api/partners/partners";

	import Entities from "../../config/EntityTypes";

	import { backoffice } from "../../mixins/modules/backoffice";

	import ModalPartners from "./ModalPartners";
	import ModalSharePartner from "./ModalSharePartner";

	export default {
		name: "Partner",
		components: {
			ModalPartners,
			ModalSharePartner,
		},
		mixins: [backoffice],
		props: {},
		created() {
			this.currentPage = this.$route.query.page
				? parseInt(this.$route.query.page)
				: 1;
		},
		data() {
			return {
				isEmpty: false,
				currentPage: 1,
				isLoading: false,
				modalOpen: false,
				modalShareOpen: false,
				action: null,
				partner_edit: null,
				partner_share: null,
			};
		},
		methods: {
			sharePartner(ins) {
				this.partner_share = ins;
				this.modalShareOpen = true;
			},
			closeModalShare() {
				this.modalShareOpen = false;
			},
			getEntity(ent) {
				let name = Entities.filter(
					(e) => e.id.toLowerCase() == ent.toLowerCase()
				);
				if (name.length <= 0) return ent;
				return name[0].name;
			},
			deletePartner(partner) {
				this.$buefy.dialog.confirm({
					title: "Remover munícipio parceiro",
					message:
						"Tem a certeza que pretende remover <b>" +
						partner.name +
						"</b>? Esta ação não pode ser desfeita!",
					confirmText: "Sim",
					cancelText: "Não",
					type: "is-danger",
					hasIcon: true,
					onConfirm: () => {
						this.isLoading = true;

						removePartner(partner._id)
							.then((response) => {
								this.$buefy.toast.open({
									message:
										"Munícipio parceiro removida com sucesso.",
									type: "is-success",
									position: "is-bottom",
								});

								let u = this.getUserInfo;
								u.partners.map((v, i) => {
									if (v._id == partner._id) u.partners.splice(i);
								});
								this.addUser(u);

								this.removePartner(partner._id);

								this.isLoading = false;
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: "Ocorreu um erro!",
									type: "is-danger",
									position: "is-bottom",
								});
								this.isLoading = false;
							});
					},
				});
			},
			editPartner(ins) {
				this.modal();

				this.updatePartner(ins);

				let u = this.getUserInfo;
				u.partners.map((v, i) => {
					if (v._id == ins._id) u.partners[i] = ins;
				});
				this.addUser(u);
			},
			openEditPartner(ins) {
				this.partner_edit = ins;
				this.openModal("edit");
			},
			addPartner(new_partner) {
				this.modal();

				this.setPartners(this.getPartners.concat([new_partner]));

				let u = this.getUserInfo;
				u.partners.push(new_partner);
				this.addUser(u);
			},
			openModal(action) {
				this.action = action;
				this.modalOpen = true;
			},
			modal() {
				this.modalOpen = !this.modalOpen;
			},
			changePage(a) {
				this.scrollToTop();
				if (this.$route.query != undefined) {
					let q = JSON.parse(JSON.stringify(this.$route.query));
					q.page = a;
					this.$router.push({ query: q });
				} else {
					this.$router.push({ query: { page: a } });
				}
			},
		},
	};
</script>