<template>
	<div class="modal" :class="{ 'is-active': modalOpen }">
		<ValidationObserver v-slot="{ handleSubmit }">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p v-if="action == 'edit'" class="modal-card-title">Editar Munícipio Parceiro</p>
					<p v-else class="modal-card-title">Criar Munícipio Parceiro</p>
					<button @click="closeModal()" class="delete" aria-label="close"></button>
				</header>
				<section class="modal-card-body" style="background: white;">
					<b-field label="Nome">
						<div class="control">
							<ValidationProvider name="Nome" rules="required" v-slot="{ errors }">
								<input v-model="partner.name" class="input" type="text" />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</b-field>
					<b-field label="Entidade">
						<ValidationProvider name="Entidade" rules="required" v-slot="{ errors }">
							<b-select
								v-model="partner.entity"
								name="origin"
								expanded
								placeholder="Selecione o tipo de entidade"
							>
								<option
									v-for="type in entityTypes"
									v-bind:value="type.id"
									v-bind:key="type.id"
									selected
								>{{ type.name }}</option>
							</b-select>
							<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
						</ValidationProvider>
					</b-field>

					<b-field label="País">
						<ValidationProvider name="País" rules="required" v-slot="{ errors }">
							<b-select
								:disabled="countryDisabled"
								@input="getDistricts()"
								v-model="countrySelected"
								name="origin"
								expanded
							>
								<option v-for="option in countries" v-bind:value="option">{{ option.country }}</option>
							</b-select>
							<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
						</ValidationProvider>
					</b-field>

					<b-field label="Distrito">
						<ValidationProvider name="Distrito" rules="required" v-slot="{ errors }">
							<b-select
								:disabled="districtDisabled"
								@input="getMunicipalities()"
								v-model="districtSelected"
								name="origin"
								expanded
							>
								<option v-for="option in districts" v-bind:value="option">{{ option.district_name }}</option>
							</b-select>
							<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
						</ValidationProvider>
					</b-field>

					<b-field label="Município">
						<ValidationProvider name="Município" rules="required" v-slot="{ errors }">
							<b-select
								:disabled="municipalityDisabled"
								v-model="municipalitySelected"
								name="origin"
								expanded
							>
								<option
									v-for="option in municipalities"
									v-bind:value="option"
								>{{ option.municipality_name }}</option>
							</b-select>
							<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
						</ValidationProvider>
					</b-field>
					<b-field label="Morada">
						<div class="control">
							<ValidationProvider name="Morada" rules="required" v-slot="{ errors }">
								<input v-model="partner.location.address" class="input" type="text" placeholder required />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</b-field>
				</section>
				<footer class="modal-card-foot">
					<b-button
						:loading="isLoading"
						v-if="action == 'edit'"
						class="is-primary-color is-fullwidth is-medium"
						@click="handleSubmit(_editPartner)"
					>Guardar</b-button>
					<b-button
						:loading="isLoading"
						v-else
						class="is-primary-color is-fullwidth is-medium"
						@click="handleSubmit(createPartner)"
					>Guardar</b-button>
				</footer>
			</div>
		</ValidationObserver>
	</div>
</template>

<script>
	import { createPartner, updatePartner } from "./../../api/partners/partners";

	import Countries from "@/config/Countries.json";
	import Districts from "@/config/Districts.json";
	import Municipalities from "@/config/Municipality.json";
	import EntityTypes from "@/config/EntityTypes.json";

	export default {
		name: "ModalPartners",
		components: {},
		props: {
			action: {
				type: String,
				default: "add",
			},
			partner_edit: Object,
			modalOpen: Boolean,
		},
		created() {
			if (this.action != "edit") return;

			this.partner = this.partner_edit;

			if (this.partner.location.country) {
				for (let c of this.countries)
					if (c.country == this.partner.location.country)
						this.countrySelected = c;
				this.getDistricts();
				if (this.partner.location.district) {
					for (let c of this.districts)
						if (c.district_name == this.partner.location.district)
							this.districtSelected = c;
					this.getMunicipalities();
					if (this.partner.location.municipality) {
						for (let c of this.municipalities)
							if (
								c.municipality_name ==
								this.partner.location.municipality
							)
								this.municipalitySelected = c;
					}
				}
			}
		},
		data() {
			return {
				districts: [],
				municipalities: [],
				municipalityDisabled: true,
				municipalitySelected: null,
				entityTypes: EntityTypes,
				districtDisabled: true,
				districtSelected: null,
				countries: Countries,
				countrySelected: null,
				countryDisabled: false,
				isLoading: false,
				partner: {
					location: {
						address: "",
						municipality: "",
						district: "",
						country: "",
					},
					name: "",
					entity: "",
					NIF: null,
					email: "",
					contact: "",
				},
			};
		},
		methods: {
			closeModal() {
				this.$emit("closemodal");
			},
			_editPartner() {
				this.isLoading = true;
				this.partner.location.country = this.countrySelected.country;
				this.partner.location.district = this.districtSelected.district_name;
				this.partner.location.municipality = this.municipalitySelected.municipality_name;

				updatePartner(this.partner._id, this.partner)
					.then((response) => {
						this.$emit("editpartner", response.data.body);

						this.$buefy.toast.open({
							message: "Munícipio parceiro editado com sucesso.",
							type: "is-success",
							position: "is-bottom",
						});
						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							type: "is-danger",
							position: "is-bottom",
						});
						this.isLoading = false;
					});
			},
			createPartner() {
				this.isLoading = true;
				this.partner.location.country = this.countrySelected.country;
				this.partner.location.district = this.districtSelected.district_name;
				this.partner.location.municipality = this.municipalitySelected.municipality_name;

				let data = {
					location: {
						address: this.partner.location.address,
						municipality: this.partner.location.municipality,
						district: this.partner.location.district,
						country: this.partner.location.country,
					},
					name: this.partner.name,
					entity: this.partner.entity,
					//NIF: this.partner.NIF,
					//email: this.partner.email,
					//contact: this.partner.contact
				};
				createPartner(data)
					.then((response) => {
						this.$emit("addpartner", response.data.body);

						this.$buefy.toast.open({
							message: "Munícipio parceiro adicionado com sucesso.",
							type: "is-success",
							position: "is-bottom",
						});
						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							type: "is-danger",
							position: "is-bottom",
						});
						this.isLoading = false;
					});
			},
			getDistricts() {
				this.districts = [];
				this.districtSelected = null;
				for (let m of Districts) {
					if (this.countrySelected.country_id == m.country_id)
						this.districts.push(m);
				}
				this.districtDisabled = false;
			},
			getMunicipalities() {
				this.municipalities = [];
				this.municipalitySelected = null;
				for (let m of Municipalities) {
					if (this.districtSelected.district_code == m.district_code)
						this.municipalities.push(m);
				}
				this.municipalityDisabled = false;
			},
		},
	};
</script>