<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>
		<div class="columns is-multiline is-mobile is-centered">
			<div
				style="text-align: center;
      margin-bottom: 1rem;"
				class="column is-fullwidth is-centered"
			>
				<h5 class="title is-5">Locais</h5>
			</div>
		</div>

		<div class="columns is-multiline is-mobile is-centered">
			<template>
				<b-table
					:data="getPlaces"
					:paginated="true"
					:per-page="10"
					:current-page.sync="currentPage"
					@page-change="changePage"
					:mobile-cards="true"
				>
					<b-table-column field="name" sortable label="Nome" centered v-slot="props">{{ props.row.name }}</b-table-column>
					<b-table-column field="address" label="Morada" centered v-slot="props">{{ props.row.address }}</b-table-column>

					<b-table-column
						field="municipality"
						sortable
						label="Município"
						centered
						v-slot="props"
					>{{ props.row.municipality }}</b-table-column>

					<b-table-column
						field="district"
						sortable
						label="Distrito"
						centered
						v-slot="props"
					>{{ props.row.district }}</b-table-column>

					<b-table-column
						field="country"
						sortable
						label="País"
						centered
						v-slot="props"
					>{{ props.row.country }}</b-table-column>
					<b-table-column
						field="zip_code"
						sortable
						label="Código postal"
						centered
						v-slot="props"
					>{{ props.row.zip_code }}</b-table-column>
					<b-table-column
						field="coordinates"
						sortable
						label="Coordenadas"
						centered
						v-slot="props"
					>{{ props.row.coordinates.lat }} | {{ props.row.coordinates.lng }}</b-table-column>
					<b-table-column field="actions" label="Ações" centered v-slot="props">
						<b-button
							style="margin-right: 5px;"
							type="is-secondary-color is-small"
							@click="openEditPlace(JSON.parse(JSON.stringify(props.row)))"
						>
							<b-icon icon="border-color" size="is-small"></b-icon>
						</b-button>
						<b-button
							type="is-danger is-small"
							@click="deletePlace(JSON.parse(JSON.stringify(props.row)))"
						>
							<b-icon icon="delete" size="is-small"></b-icon>
						</b-button>
					</b-table-column>

					<template slot="empty">
						<section class="section">
							<div class="content has-text-grey has-text-centered">
								<p>Não existem locais!</p>
							</div>
						</section>
					</template>
				</b-table>
			</template>
		</div>

		<div style="margin-top:1rem;" class="columns is-multiline is-mobile is-centered">
			<div class="column is-7"></div>
			<div class="column">
				<b-button @click="openModal('add')" class="is-primary-color">Criar local</b-button>
			</div>
		</div>

		<ModalPlaces
			v-if="modalOpen"
			@editplace="editPlace"
			@addplace="addPlace"
			@closemodal="modal"
			:place_edit="place_edit"
			:modalOpen="modalOpen"
			:action="action"
		/>
	</div>
</template>


<script>
	import { removePlace } from "./../../api/places/places";

	import ModalPlaces from "./ModalPlaces";

	import { backoffice } from "../../mixins/modules/backoffice";

	export default {
		name: "Places",
		components: {
			ModalPlaces,
		},
		mixins: [backoffice],
		props: {},
		created() {
			this.currentPage = this.$route.query.page
				? parseInt(this.$route.query.page)
				: 1;
		},
		data() {
			return {
				isEmpty: false,
				currentPage: 1,
				places: [],
				isLoading: false,
				modalOpen: false,
				action: null,
				place_edit: null,
			};
		},
		methods: {
			deletePlace(place) {
				this.$buefy.dialog.confirm({
					title: "Remover local",
					message:
						"Tem a certeza que pretende remover o local <b>" +
						place.name +
						"</b>? Esta ação não pode ser desfeita!",
					confirmText: "Sim",
					cancelText: "Não",
					type: "is-danger",
					hasIcon: true,
					onConfirm: () => {
						this.isLoading = true;

						removePlace(place._id)
							.then((response) => {
								this.$buefy.toast.open({
									message: "Local removido com sucesso.",
									type: "is-success",
									position: "is-bottom",
								});

								this.removePlace(place._id);

								let u = this.getUserInfo;
								u.places.map((v, i) => {
									if (v._id == place._id) u.places.splice(i);
								});
								this.addUser(u);

								this.isLoading = false;
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: "Ocorreu um erro!",
									type: "is-danger",
									position: "is-bottom",
								});
								this.isLoading = false;
							});
					},
				});
			},
			editPlace(ins) {
				this.modal();

				this.updatePlace(ins);

				let u = this.getUserInfo;
				u.places.map((v, i) => {
					if (v._id == ins._id) u.places[i] = ins;
				});
				this.addUser(u);
			},
			openEditPlace(ins) {
				this.place_edit = ins;
				this.openModal("edit");
			},
			addPlace(new_place) {
				this.modal();
				this.setPlaces(this.getPlaces.concat([new_place]));

				let u = this.getUserInfo;
				u.places.push(new_place);
				this.addUser(u);
			},
			openModal(action) {
				this.action = action;
				this.modalOpen = true;
			},
			modal() {
				this.modalOpen = !this.modalOpen;
			},
			changePage(a) {
				this.scrollToTop();
				if (this.$route.query != undefined) {
					let q = JSON.parse(JSON.stringify(this.$route.query));
					q.page = a;
					this.$router.push({ query: q });
				} else {
					this.$router.push({ query: { page: a } });
				}
			},
		},
	};
</script>