<template>
	<div>
		<div class="modal is-active">
			<div class="modal-background"></div>
			<div class="modal-card">
				<!-- Auth modal header-->
				<header class="modal-card-head">
					<p class="modal-card-title">Convidar para instituição</p>
					<button class="delete" aria-label="close" @click="$emit('closemodal')"></button>
				</header>

				<section class="modal-card-body has-text-centered">
					<!-- Quote -->
					<h1 class="title has-text-centered is-5 is-bold has-text-dark">{{ institution.name }}</h1>

					<b-field grouped>
						<b-input v-model="link" name="invite-link" type="text" disabled expanded></b-input>
						<!-- Copy invite link to clipboard -->
						<a class="button is-dark" @click="copyToClipboard()">
							<span class="icon is-size-5">
								<i class="mdi mdi-content-copy"></i>
							</span>
						</a>
					</b-field>

					<ShareNetwork
						network="facebook"
						:url="link"
						:title="title"
						:description="description"
						:quote="title"
						:hashtags="hashtags"
					>
						<a @click.prevent class="button social-button button-facebook">
							<b-icon icon="facebook"></b-icon>
						</a>
					</ShareNetwork>

					<ShareNetwork
						network="twitter"
						:url="link"
						:title="title"
						:twitter-user="twitter_user"
						:description="description"
						:quote="title"
						:hashtags="hashtags"
					>
						<a @click.prevent class="button social-button button-twitter">
							<b-icon icon="twitter"></b-icon>
						</a>
					</ShareNetwork>

					<ShareNetwork
						network="whatsapp"
						:url="link"
						:title="title"
						:description="description"
						:quote="title"
						:hashtags="hashtags"
					>
						<a @click.prevent class="button social-button button-whatsapp">
							<b-icon icon="whatsapp"></b-icon>
						</a>
					</ShareNetwork>
					<a v-if="navigator_share" @click="share()" class="button social-button button-share">
						<b-icon icon="share-variant"></b-icon>
					</a>
				</section>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: "ModalShareInstitution",
		components: {
            
		},
		props: {
			institution: Object,
		},
		data() {
			return {
				hashtags: "tobegreen",
				title: null,
				description: null,
				twitter_user: null,
				navigator_share: false,
				link: null,
			};
		},
		created() {
			if (navigator.share) this.navigator_share = true;

			this.link =
				window.location.hostname + "/institutions/" + this.institution._id;
			this.title = this.institution.name;
			this.description = this.institution.name;
		},
		methods: {
			onError(a) {
				alert(a);
			},
			onSuccess(a) {
				alert(a);
			},
			share() {
				const data = {
					title: this.title,
					text: this.description,
					url: this.link,
				};

				if (navigator.share) {
					navigator
						.share(data)
						.then((succ) => {})
						.catch((error) => {
							console.log(error);
						});
				}
			},
			copyToClipboard() {
				this.$copyText(this.link).then(
					(e) => {
						this.$buefy.toast.open({
							message: "Link copiado!",
							position: "is-bottom",
							type: "is-success",
						});
					},
					(e) => {
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							position: "is-bottom",
							type: "is-danger",
						});
					}
				);
			},
		},
	};
</script>

<style lang="scss">
	@import "../../assets/sass/main";

	.button-facebook {
		background-color: $facebook !important;
		margin-right: 1rem !important;
		color: $white;
	}

	.button-whatsapp {
		background-color: $whatsapp !important;
		margin-right: 1rem !important;
		color: $white;
	}

	.button-twitter {
		background-color: $twitter !important;
		margin-right: 1rem !important;
		color: $white;
	}

	.button-share {
		background-color: $dark !important;
		margin-right: 1rem !important;
		color: $white;
	}

	.social-button {
		color: white !important;
	}

	.social-button:hover {
		color: $white;
		opacity: 0.7;
	}

	.has-space {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}
</style>