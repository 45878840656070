export const backoffice = {
    computed: {
        getGlads() {
            return this.$store.getters['backoffice/getGlads']
        },
        getInstitutions() {
            return this.$store.getters['backoffice/getInstitutions']
        },
        getPlaces() {
            return this.$store.getters['backoffice/getPlaces']
        },
        getPartners() {
            return this.$store.getters['backoffice/getPartners']
        },
        getInfo() {
            return this.$store.getters['backoffice/getInfo']
        },
        getCurrentTab() {
            return this.$store.getters['backoffice/getCurrentTab']
        }
    },
    methods: {
        setGlads(obj) {
            this.$store.commit('backoffice/setGlads', obj)
        },
        setInstitutions(obj) {
            this.$store.commit('backoffice/setInstitutions', obj)
        },
        setPlaces(obj) {
            this.$store.commit('backoffice/setPlaces', obj)
        },
        setPartners(obj) {
            this.$store.commit('backoffice/setPartners', obj)
        },
        setInfo(obj) {
            this.$store.commit('backoffice/setInfo', obj)
        },
        setCurrentTab(obj) {
            this.$store.commit('backoffice/setCurrentTab', obj)
        },
        removeGlad(_id) {
            this.$store.commit('backoffice/removeGlad', _id)
        },
        removeInstitution(_id) {
            this.getGlads.forEach((glad, i) => {
                glad.institutions.forEach((institution, k) => {
                    if (institution._id == _id)
                        this.$delete(this.getGlads[i].institutions, k);
                })
            })
            this.$store.commit('backoffice/removeInstitution', _id)
        },
        removePlace(_id) {
            this.getGlads.forEach((glad, i) => {
                glad.places.forEach((place, k) => {
                    if (place._id == _id)
                        this.$delete(this.getGlads[i].places, k);
                })
            })
            this.$store.commit('backoffice/removePlace', _id)
        },
        removePartner(_id) {
            this.getGlads.forEach((glad, i) => {
                glad.partners.forEach((partner, k) => {
                    if (partner._id == _id)
                        this.$delete(this.getGlads[i].partners, k);
                })
            })
            this.$store.commit('backoffice/removePartner', _id)
        },
        updateGlad(obj) {
            this.getGlads.forEach((glad, i) => {
                glad.institutions.forEach((institution, k) => {
                    if (institution._id == obj._id)
                        this.$set(this.getGlads[i].institutions, k, obj);
                })
            })
            this.$store.commit('backoffice/updateGlad', obj)
        },
        updatePlace(obj) {
            this.getGlads.forEach((glad, i) => {
                glad.places.forEach((place, k) => {
                    if (place._id == obj._id)
                        this.$set(this.getGlads[i].places, k, obj);
                })
            })
            this.$store.commit('backoffice/updatePlace', obj)
        },
        updatePartner(obj) {
            this.getGlads.forEach((glad, i) => {
                glad.partners.forEach((partner, k) => {
                    if (partner._id == obj._id)
                        this.$set(this.getGlads[i].partners, k, obj);
                })
            })
            this.$store.commit('backoffice/updatePartner', obj)
        },
        updateInstitution(obj) {
            this.getGlads.forEach((glad, i) => {
                glad.institutions.forEach((institution, k) => {
                    if (institution._id == obj._id)
                        this.$set(this.getGlads[i].institutions, k, obj);
                })
            })
            this.$store.commit('backoffice/updateInstitution', obj)
        }
    }
}