<template>
	<div>
		<b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>
		<div class="columns is-multiline is-mobile is-centered">
			<div
				style="    text-align: center;
    margin-bottom: 1rem;"
				class="column is-fullwidth is-centered"
			>
				<h5 class="title is-5">Glads</h5>
			</div>
		</div>

		<div class="columns is-multiline is-mobile is-centered">
			<!-- <template> -->
			<div class="column is-9">
				<b-table
					:data="getGlads"
					ref="table"
					paginated
					per-page="10"
					@page-change="changePage"
					detailed
					:show-detail-icon="true"
					:current-page.sync="currentPage"
					:mobile-cards="true"
				>
					<b-table-column
						field="info.name"
						label="Nome"
						sortable
						centered
						v-slot="props"
					>{{ props.row.info.name }}</b-table-column>

					<b-table-column
						field="auth.email"
						sortable
						label="Email"
						centered
						v-slot="props"
					>{{ props.row.auth.email }}</b-table-column>

					<b-table-column field="actions" label="Ações" centered v-slot="props">
						<b-button
							style="margin-right: 5px;"
							type="is-secondary-color is-small"
							@click="openEditGlad(JSON.parse(JSON.stringify(props.row)))"
						>
							<b-icon icon="border-color" size="is-small"></b-icon>
						</b-button>
						<b-button
							type="is-danger is-small"
							@click="deleteGlad(JSON.parse(JSON.stringify(props.row)))"
						>
							<b-icon icon="delete" size="is-small"></b-icon>
						</b-button>
					</b-table-column>

					<template slot="detail" slot-scope="props">
						<article class="media">
							<div class="media-content">
								<div class="content">
									<div class="columns">
										<div class="column">
											<strong>Instituições</strong>
											<br />
											<p v-if="props.row.institutions.length == 0">Sem Instituições associadas!</p>
											<p
												v-else
												v-for="institution in props.row.institutions"
												v-bind:key="institution._id"
											>{{ institution.name }}</p>
										</div>
										<div class="column">
											<strong>Locais</strong>
											<br />
											<p v-if="props.row.places.length == 0">Sem locais associados!</p>
											<p v-else v-for="place in props.row.places" v-bind:key="place._id">{{ place.name }}</p>
										</div>
										<div class="column">
											<strong>Municípios parceiros</strong>
											<br />
											<p v-if="props.row.partners.length == 0">Sem parceiros associados!</p>
											<p
												v-else
												v-for="partner in props.row.partners"
												v-bind:key="partner._id"
											>{{ partner.name }}</p>
										</div>
									</div>
								</div>
							</div>
						</article>
					</template>

					<template slot="empty">
						<section class="section">
							<div class="content has-text-grey has-text-centered">
								<p>Não existem Glads !</p>
							</div>
						</section>
					</template>
				</b-table>
			</div>

			<!-- </template> -->
		</div>

		<div
			style="margin-top:1rem; margin-bottom:2rem;"
			class="columns is-multiline is-mobile is-centered"
		>
			<div class="column is-9"></div>
			<div class="column">
				<b-button @click="openAddGlad" class="is-primary-color">Criar Glad</b-button>
			</div>
		</div>

		<!-- MODAL -->
		<ModalGlad
			:_institutions="institutions"
			:_places="places"
			v-if="modalOpen"
			@editglad="editGlad"
			@addglad="addGlad"
			@closemodal="modal"
			:glad_edit="glad_edit"
			:modalOpen="modalOpen"
			:action="action"
		/>
	</div>
</template>
<script>
	//API
	import { removeGlad } from "./../../api/user/user";

	import ModalGlad from "./ModalGlad";

	import { backoffice } from "../../mixins/modules/backoffice";

	export default {
		name: "Glads",
		mixins: [backoffice],
		components: {
			ModalGlad,
		},
		props: {},
		created() {
			this.currentPage = this.$route.query.page
				? parseInt(this.$route.query.page)
				: 1;
		},
		data() {
			return {
				currentPage: 1,
				glads: [],
				isLoading: false,
				modalOpen: false,
				glad_edit: null,
				action: null,
				institutions: [],
				places: [],
			};
		},
		methods: {
			openEditGlad(glad) {
				this.glad_edit = glad;
				this.action = "edit";
				this.modal();
			},
			openAddGlad() {
				this.action = "add";
				this.modal();
			},
			deleteGlad(glad) {
				this.$buefy.dialog.confirm({
					title: "Remover Glad",
					message:
						"Tem a certeza que pretende remover o Glad <b>" +
						glad.info.name +
						"</b>? Esta ação não pode ser desfeita!",
					confirmText: "Sim",
					cancelText: "Não",
					type: "is-danger",
					hasIcon: true,
					onConfirm: () => {
						this.isLoading = true;

						removeGlad(glad._id)
							.then((response) => {
								this.$buefy.toast.open({
									message: "Glad removido com sucesso.",
									type: "is-success",
									position: "is-bottom",
								});

								this.removeGlad(glad._id);

								this.isLoading = false;
							})
							.catch((error) => {
								console.log(error);
								this.$buefy.toast.open({
									message: "Ocorreu um erro!",
									type: "is-danger",
									position: "is-bottom",
								});
								this.isLoading = false;
							});
					},
				});
			},
			editGlad(glad) {
				this.modal();
				this.updateGlad(glad);
			},
			addGlad(new_glad) {
				this.modal();
				this.setGlads(this.getGlads.concat([new_glad]));
			},
			toggle(row) {
				this.$refs.table.toggleDetails(row);
			},
			modal() {
				this.modalOpen = !this.modalOpen;
			},
			changePage(a) {
				this.scrollToTop();
				if (this.$route.query != undefined) {
					let q = JSON.parse(JSON.stringify(this.$route.query));
					q.page = a;
					this.$router.push({ query: q });
				} else {
					this.$router.push({ query: { page: a } });
				}
			},
		},
	};
</script>