<template>
	<div class="modal" :class="{ 'is-active': modalOpen }">
		<ValidationObserver v-slot="{ handleSubmit }">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p v-if="action == 'edit'" class="modal-card-title">Editar Glad</p>
					<p v-else class="modal-card-title">Registar Glad</p>
					<button @click="closeModal()" class="delete" aria-label="close"></button>
				</header>
				<section class="modal-card-body" style="background: white;">
					<b-field label="Nome">
						<div class="control">
							<ValidationProvider name="Nome" rules="required" v-slot="{ errors }">
								<input v-model="glad.info.name" name="name" class="input" type="text" />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</b-field>

					<b-field label="Email">
						<div class="control">
							<ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
								<input v-model="glad.auth.email" name="email" class="input" type="email" />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</b-field>

					<b-field v-if="action == 'edit'" label="Nova password">
						<div class="control">
							<input v-model="glad.auth.password" name="password" class="input" type="password" />
						</div>
					</b-field>
					<b-field v-else label="Password">
						<div class="control">
							<ValidationProvider name="Password" rules="required" v-slot="{ errors }">
								<input v-model="glad.auth.password" name="password" class="input" type="password" />
								<span v-if="errors.length>0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</div>
					</b-field>

					<b-field label="Instituições">
						<b-select
							@input="addTagInstitution"
							v-model="selectedInstitution"
							expanded
							placeholder="Selecione uma instituição"
						>
							<option
								:key="i"
								v-for="(institution, i) in institutions"
								:value="institution"
							>{{ institution.name }}</option>
						</b-select>
					</b-field>
					<b-taglist>
						<b-tag
							:key="i"
							type="is-primary-color"
							closable
							v-for="(ins, i) in tagsInstitutions"
							@close="removeTagInstitution(ins)"
						>{{ins.name}}</b-tag>
					</b-taglist>

					<b-field label="Locais">
						<b-select
							@input="addTagPlace"
							v-model="selectedPlace"
							expanded
							placeholder="Selecione um local"
						>
							<option :key="i" v-for="(place, i) in places" :value="place">{{ place.name }}</option>
						</b-select>
					</b-field>
					<b-taglist>
						<b-tag
							type="is-primary-color"
							closable
							:key="i"
							v-for="(ins, i) in tagsPlaces"
							@close="removeTagPlace(ins)"
						>{{ins.name}}</b-tag>
					</b-taglist>

					<b-field label="Municípios parceiros">
						<b-select
							@input="addTagPartner"
							v-model="selectedPartner"
							expanded
							placeholder="Selecione um município"
						>
							<option :key="i" v-for="(partner, i) in partners" :value="partner">{{ partner.name }}</option>
						</b-select>
					</b-field>
					<b-taglist>
						<b-tag
							type="is-primary-color"
							closable
							:key="i"
							v-for="(part, i) in tagsPartners"
							@close="removeTagPartner(part)"
						>{{part.name}}</b-tag>
					</b-taglist>
				</section>
				<footer class="modal-card-foot">
					<b-button
						:loading="isLoading"
						v-if="action == 'edit'"
						@click="handleSubmit(editGlad)"
						class="is-primary-color is-medium is-fullwidth"
					>Guardar</b-button>
					<b-button
						:loading="isLoading"
						v-else
						@click="handleSubmit(createGlad)"
						class="is-primary-color is-medium is-fullwidth"
					>Guardar</b-button>
				</footer>
			</div>
		</ValidationObserver>
	</div>
</template>

<script>
	import { createGlad, updateGlad } from "../../api/user/user.js";

	import { backoffice } from "../../mixins/modules/backoffice";

	export default {
		name: "ModalGlad",
		components: {},
		mixins: [backoffice],
		props: {
			action: {
				type: String,
				default: "add",
			},
			glad_edit: Object,
			modalOpen: Boolean,
		},
		created() {
			this.institutions = this.getInstitutions;
			this.places = this.getPlaces;
			this.partners = this.getPartners;
			console.log(this.getPartners)

			if (this.action == "edit") {
				this.glad = this.glad_edit;
				for (let i of this.glad.institutions) {
					for (let c of this.institutions) {
						if (i._id == c._id) this.tagsInstitutions.push(c);
					}
				}
				for (let i of this.glad.places) {
					for (let c of this.places) {
						if (i._id == c._id) this.tagsPlaces.push(c);
					}
				}
				
				for (let i of this.glad.partners) {
					for (let c of this.partners) {
						if (i._id == c._id) this.tagsPartners.push(c);
					}
				}

				let arr = [];
				for (let i of this.institutions) {
					let exists = false;
					for (let c of this.tagsInstitutions) {
						if (i._id == c._id) {
							exists = true;
						}
					}
					if (!exists) arr.push(i);
				}
				this.institutions = arr;

				let arrP = [];
				for (let i of this.places) {
					let exists = false;
					for (let c of this.tagsPlaces) {
						if (i._id == c._id) {
							exists = true;
						}
					}
					if (!exists) arrP.push(i);
				}
				this.places = arrP;

				let arrPart = [];
				for (let i of this.partners) {
					let exists = false;
					for (let c of this.tagsPartners) {
						if (i._id == c._id) {
							exists = true;
						}
					}
					console.log(i)
					if (!exists) arrPart.push(i);
				}
				this.partners = arrPart;
			}
			
			this.sortInstitutions();
			this.sortPlaces();
			this.sortPartners();
		},
		data() {
			return {
				isLoading: false,
				selectedInstitution: null,
				selectedPlace: null,
				selectedPartner: null,
				tagsInstitutions: [],
				tagsPlaces: [],
				tagsPartners: [],
				institutions: [],
				places: [],
				partners: [],
				glad: {
					auth: {
						email: null,
						password: null,
					},
					info: {
						name: [],
					},
					institutions: [],
					places: [],
					partners: [],
				},
			};
		},
		computed: {},
		methods: {
			closeModal() {
				this.$emit("closemodal");
			},
			sortInstitutions() {
				this.tagsInstitutions.sort((a, b) => (a.name > b.name ? 1 : -1));
				this.institutions.sort((a, b) => (a.name > b.name ? 1 : -1));
			},
			sortPlaces() {
				this.tagsPlaces.sort((a, b) => (a.name > b.name ? 1 : -1));
				this.places.sort((a, b) => (a.name > b.name ? 1 : -1));
			},
			sortPartners() {
				this.tagsPartners.sort((a, b) => (a.name > b.name ? 1 : -1));
				this.partners.sort((a, b) => (a.name > b.name ? 1 : -1));
			},
			editGlad() {
				this.isLoading = true;
				this.glad.institutions = this.tagsInstitutions.map((x) => x._id);
				this.glad.places = this.tagsPlaces.map((x) => x._id);
				this.glad.partners = this.tagsPartners.map((x) => x._id);

				let data = JSON.parse(JSON.stringify(this.glad));

				if (!this.glad.auth.password) delete data.auth.password;

				updateGlad(this.glad._id, this.glad)
					.then((response) => {
						this.$emit("editglad", response.data.body);

						this.$buefy.toast.open({
							message: "Glad editado com sucesso.",
							type: "is-success",
							position: "is-bottom",
						});
						this.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							type: "is-danger",
							position: "is-bottom",
						});
						this.isLoading = false;
					});
			},
			createGlad() {
				this.isLoading = true;

				this.glad.institutions = this.tagsInstitutions.map((x) => x._id);
				this.glad.places = this.tagsPlaces.map((x) => x._id);
				this.glad.partners = this.tagsPartners.map((x) => x._id);

				createGlad(this.glad)
					.then((response) => {
						this.$emit("addglad", response.data.body);

						this.$buefy.toast.open({
							message: "Glad adicionado com sucesso.",
							type: "is-success",
							position: "is-bottom",
						});
						this.isLoading = false;
					})
					.catch((error) => {
						this.isLoading = false;
						if (error.response.data.code == "EmailDuplicate")
							return this.$buefy.toast.open({
								message: "Este email já se encontra registado!",
								type: "is-danger",
								position: "is-bottom",
							});
						console.log(error);
						this.$buefy.toast.open({
							message: "Ocorreu um erro!",
							type: "is-danger",
							position: "is-bottom",
						});
					});
			},
			addTagInstitution() {
				if (!this.selectedInstitution) return;
				this.tagsInstitutions.push(this.selectedInstitution);
				for (let i in this.institutions) {
					if (this.institutions[i]._id == this.selectedInstitution._id)
						this.institutions.splice(i, 1);
				}
				this.selectedInstitution = null;
				this.sortInstitutions();
			},
			removeTagInstitution(_ins) {
				this.institutions.push(_ins);
				for (let i in this.tagsInstitutions) {
					if (this.tagsInstitutions[i]._id == _ins._id)
						this.tagsInstitutions.splice(i, 1);
				}
				this.sortInstitutions();
			},
			addTagPlace() {
				if (!this.selectedPlace) return;
				this.tagsPlaces.push(this.selectedPlace);
				for (let i in this.places) {
					if (this.places[i]._id == this.selectedPlace._id)
						this.places.splice(i, 1);
				}
				this.selectedPlace = null;
				this.sortPlaces();
			},
			removeTagPlace(_ins) {
				this.places.push(_ins);
				for (let i in this.tagsPlaces) {
					if (this.tagsPlaces[i]._id == _ins._id)
						this.tagsPlaces.splice(i, 1);
				}
				this.sortPlaces();
			},
			addTagPartner() {
				if (!this.selectedPartner) return;
				this.tagsPartners.push(this.selectedPartner);
				for (let i in this.partners) {
					if (this.partners[i]._id == this.selectedPartner._id)
						this.partners.splice(i, 1);
				}
				this.selectedPartner = null;
				this.sortPartners();
			},
			removeTagPartner(_ins) {
				this.partners.push(_ins);
				for (let i in this.tagsPartners) {
					if (this.tagsPartners[i]._id == _ins._id)
						this.tagsPartners.splice(i, 1);
				}
				this.sortPartners();
			},
		},
	};
</script>